:root {
  --clr-primary: #F0AFA1;
  --clr-primary-light: #E57175;
  --clr-secondary: #304646;
  --clr-secondary-light: #70B1B0;
  --clr-grey-10: #DDDDD6;
  --clr-white: #fff;
}

body {
  min-height: 100vh;
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),
    url(/src/back-1.jpeg) center/cover no-repeat fixed;
  position: relative;
  overflow-x: hidden;

}

h3{
  padding-bottom: 1rem;
  color: var(--clr-white);
}

.h3-dark{
  color: var(--clr-secondary);
}

h6{
  color: var(--clr-white);
}

.home{
  height: 100vh;
}

.icons > div {
  font-size: 1.3rem;
}

.icons > div > a > i {
  color: black;
}

.icons > div > a > i:hover {
  color: var(--clr-primary-light);
}

.img-profile{
  /* border: 1px solid var(--clr-secondary); */
  border-radius: 50%;
  width: 11rem;
  box-shadow: 0 0 1px 1px rgba(0,0,0,.1);
  margin-bottom: 1rem;

}

.about-me, .educ-exp{
  background-color: rgba(48, 70, 70, 0.9); 
}

.about-me {
  /* height: 80vh; */
  color: var(--clr-white);
}

.portfolio-session{
  background-color: var(--clr-white);
}

.education-board-inside {
  border: 1px solid var(--clr-white);
  /* box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.1); */
  padding: 1rem;
  border-radius: 0.8rem;
  color: var(--clr-white);

}

@keyframes bouce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.education-icon {
  color: var(--clr-white);  
  cursor: pointer;
}

.education-icon-anim {
  animation: bouce 2s ease infinite;
}


.education-icon:hover {
  color: var(--clr-primary);
  cursor: pointer;
}

.school-link{
  text-decoration: none;
  color: var(--clr-white);
}

.school-link:hover{
  color: var(--clr-primary);
}

/* ------------ services cards -------------*/

.grid figure {  
  border-radius: 0.5rem; 
  /* border: 0.01rem solid var(--clr-secondary); */
  box-shadow: 0 0 5px 2px rgba(48, 70, 70, 0.1);
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  min-width: 320px;
  max-width: 480px;
  max-height: 360px;
  width: 48%;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  

}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
  
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*---------------*/
/***** Milo *****/
/*---------------*/

figure.effect-milo {
  background: #2e5d5a;
}

figure.effect-milo img {
  max-width: none;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
  opacity: 0.6;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-30px,0,0) scale(1.12);
  transform: translate3d(-30px,0,0) scale(1.12);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  
}

figure.effect-milo:hover img {
  opacity: 0.1;
  -webkit-transform: translate3d(0,0,0) scale(1);
  transform: translate3d(0,0,0) scale(1);
}

figure.effect-milo h2 {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1em 1.2em;
}

figure.effect-milo p {
  padding: 0 10px 0 0;
  width: 50%;
  border-right: 1px solid #fff;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-40px,0,0);
  transform: translate3d(-40px,0,0);
}

figure.effect-milo:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
